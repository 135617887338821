// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.light-mode {
    background-color: #fff;
    color: #333;
    transition: background-color 0.3s ease;
  }
  body.dark-mode {
    background-color: #1a1919;
    color: #999;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,sCAAsC;EACxC;EACA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":["body.light-mode {\n    background-color: #fff;\n    color: #333;\n    transition: background-color 0.3s ease;\n  }\n  body.dark-mode {\n    background-color: #1a1919;\n    color: #999;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
