import React, { Component } from "react";
import Fade from "react-awesome-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Nordic Giant Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>I have always had a passionate interest for IT and divides a lot of my free time
              to develop and automate my everyday life. For example my home server which is always a work in progress.
              It allowed me to save money on Cloud storage, password manager, VPS and of course this website. The under-laying architecture runs Proxmox which is a type 1 hypervisor that allows me to manager and monitor VMs. Most services are then deployed with docker. My next home-project is to deploy some of those services offsite to improve redundancy with Kubernetes clusters. </p>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
